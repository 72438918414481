.municipality-plate {
    background-image: url(../resources/municipalities.jpg);
    background-size: 630px 1800px; //90% of original 700px 2000px to make it same screen size as county plates
    background-repeat: no-repeat;
    background-position: -99px -1696px; //missing plate poster

    // COL 1
    &._1082 { // Karlshamn
        background-position: 2px -8px;
    }
    &._2023 { // Malung-Sälen
        background-position: 2px -77px;
    }
    &._2184 { // Hudiksvall
        background-position: 2px -144px;
    }
    &._1383 { // Varberg
        background-position: 2px -215px;
    }
    &._0617 { // Gnosjö
        background-position: 2px -283px;
    }
    &._0860 { // Hultsfred
        background-position: 2px -354px;
    }
    &._0781 { // Ljungby
        background-position: 2px -493px;
    }
    &._2514 { // Kalix
        background-position: 2px -560px;
    }
    &._1285 { // Eslöv
        background-position: 2px -563px;
    }
    &._1280 { // Malmö
        background-position: 2px -631px;
    }
    &._1286 { // Ystad
        background-position: 2px -699px;
    }
    &._0182 { // Nacka
        background-position: 2px -773px;
    }
    &._0160 { // Täby
        background-position: 2px -843px;
    }
    &._0481 { // Oxelösund
        background-position: 2px -913px;
    }
    &._1784 { // Arvika
        background-position: 2px -982px;
    }
    &._1766 { // Sunne
        background-position: 2px -1052px;
    }
    &._2422 { // Sorsele
        background-position: 2px -1122px;
    }
    &._2260 { // Ånge
        background-position: 2px -1192px;
    }
    &._1440 { // Ale
        background-position: 2px -1262px;
    }
    &._1471 { // Götene
        background-position: 2px -1332px;
    }
    &._1461 { // Mellerud
        background-position: 2px -1406px;
    }
    &._1472 { // Tibro
        background-position: 2px -1480px;
    }
    &._1407 { // Öckerö
        background-position: 2px -1552px;
    }
    &._1880 { // Örebro
        background-position: 2px -1621px;
    } 
    &._0561 { // Åtvidaberg
        background-position: 2px -1696px;
    } 

    // COL 2
    &._1080 { // Karlskrona
        background-position: -48px -8px;
    }
    &._2062 { // Mora
        background-position: -48px -77px;
    }
    &._2161 { // Ljusdal
        background-position: -48px -144px;
    }
    &._2326 { // Berg
        background-position: -48px -215px;
    }
    &._0643 { // Habo
        background-position: -48px -283px;
    }
    &._0821 { // Högsby
        background-position: -48px -354px;
    }
    &._0767 { // Markaryd
        background-position: -48px -493px;
    }
    &._2584 { // Kiruna
        background-position: -48px -560px;
    }
    &._1283 { // Helsingborg
        background-position: -48px -563px;
    }
    &._1273 { // Osby
        background-position: -48px -631px;
    }
    &._1277 { // Åstorp
        background-position: -48px -699px;
    }
    &._0188 { // Norrtälje
        background-position: -48px -773px;
    }
    &._0139 { // Upplands-Bro
        background-position: -48px -843px;
    }
    &._0486 { // Strängnäs
        background-position: -48px -913px;
    }
    &._1730 { // Eda
        background-position: -48px -982px;
    }
    &._1785 { // Säffle
        background-position: -48px -1052px;
    }
    &._2421 { // Storuman
        background-position: -48px -1122px;
    }
    &._2284 { // Örnsköldsvik
        background-position: -48px -1192px;
    }
    &._1489 { // Alingsås
        background-position: -48px -1262px;
    }
    &._1466 { // Herrljunga
        background-position: -48px -1332px;
    }
    &._1430 { // Munkedal
        background-position: -48px -1406px;
    }
    &._1498 { // Tidaholm
        background-position: -48px -1480px;
    }
    &._1882 { // Askersund
        background-position: -48px -1552px;
    }
    &._0560 { // Boxholm
        background-position: -48px -1621px;
    } 
    &._0509 { // Ödeshög
        background-position: -48px -1696px;
    } 
 
    // COL 3
    &._1081 { // Ronneby
        background-position: -99px -8px;
    }
    &._2034 { // Orsa
        background-position: -99px -77px;
    }
    &._2132 { // Nordanstig
        background-position: -99px -144px;
    }
    &._2305 { // Bräcke
        background-position: -99px -215px;
    }
    &._0680 { // Jönköping
        background-position: -99px -283px;
    }
    &._0880 { // Kalmar
        background-position: -99px -354px;
    }
    &._0763 { // Tingsryd
        background-position: -99px -493px;
    }
    &._2580 { // Luleå
        background-position: -99px -560px;
    }
    &._1293 { // Hässleholm
        background-position: -99px -563px;
    }
    &._1275 { // Perstorp
        background-position: -99px -631px;
    }
    &._1292 { // Ängelholm
        background-position: -99px -699px;
    }
    &._0140 { // Nykvarn
        background-position: -99px -773px;
    }
    &._0114 { // Upplands-Väsby
        background-position: -99px -843px;
    }
    &._0488 { // Trosa
        background-position: -99px -913px;
    }
    &._1782 { // Filipstad
        background-position: -99px -982px;
    }
    &._1737 { // Torsby
        background-position: -99px -1052px;
    }
    &._2480 { // Umeå
        background-position: -99px -1122px;
    }
    &._1984 { // Arboga
        background-position: -99px -1192px;
    }
    &._1460 { // Bengtsfors
        background-position: -99px -1262px;
    }
    &._1497 { // Hjo
        background-position: -99px -1332px;
    }
    &._1481 { // Mölndal
        background-position: -99px -1406px;
    }
    &._1419 { // Tjörn
        background-position: -99px -1480px;
    }
    &._1862 { // Degerfors
        background-position: -99px -1552px;
    }
    &._0562 { // Finspång
        background-position: -99px -1621px;
    } 

    // COL 4
    &._1060 { // Olofström
        background-position: -149px -8px;
    }
    &._2031 { // Rättvik
        background-position: -149px -77px;
    }
    &._2101 { // Ockelbo
        background-position: -149px -144px;
    }
    &._2361 { // Härjedalen
        background-position: -149px -215px;
    }
    &._0642 { // Mullsjö
        background-position: -149px -283px;
    }
    &._0861 { // Mönsterås
        background-position: -149px -354px;
    }
    &._0760 { // Uppvidinge
        background-position: -149px -493px;
    }
    &._2521 { // Pajala
        background-position: -149px -560px;
    }
    &._1284 { // Höganäs
        background-position: -149px -563px;
    }
    &._1291 { // Simrishamn
        background-position: -149px -631px;
    }
    &._1257 { // Örkelljunga
        background-position: -149px -699px;
    }
    &._0192 { // Nynäshamn
        background-position: -149px -773px;
    }
    &._0115 { // Vallentuna
        background-position: -149px -843px;
    }
    &._0428 { // Vingåker
        background-position: -149px -913px;
    }
    &._1763 { // Forshaga
        background-position: -149px -982px;
    }
    &._1765 { // Årjäng
        background-position: -149px -1052px;
    }
    &._2462 { // Vilhelmina
        background-position: -149px -1122px;
    }
    &._1982 { // Fagersta
        background-position: -149px -1192px;
    }
    &._1443 { // Bollebygd
        background-position: -149px -1262px;
    }
    &._1401 { // Härryda
        background-position: -149px -1332px;
    }
    &._1421 { // Orust
        background-position: -149px -1406px;
    }
    &._1452 { // Tranemo
        background-position: -149px -1480px;
    }
    &._1861 { // Hallsberg
        background-position: -149px -1552px;
    }
    &._0513 { // Kinda
        background-position: -149px -1621px;
    }  

    // COL 5
    &._1083 { // Sölvesborg
        background-position: -201px -8px;
    }
    &._2061 { // Smedjebacken
        background-position: -201px -77px;
    }
    &._2121 { // Ovanåker
        background-position: -201px -144px;
    }
    &._2309 { // Krokom
        background-position: -201px -215px;
    }
    &._0682 { // Nässjö
        background-position: -201px -283px;
    }
    &._0840 { // Mörbylånga
        background-position: -201px -354px;
    }
    &._0780 { // Växjö
        background-position: -201px -493px;
    }
    &._2581 { // Piteå
        background-position: -201px -560px;
    }
    &._1266 { // Hörby
        background-position: -201px -563px;
    }
    &._1265 { // Sjöbo
        background-position: -201px -631px;
    }
    &._1256 { // Östra Göinge
        background-position: -201px -699px;
    }
    &._0128 { // Salem
        background-position: -201px -773px;
    }
    &._0187 { // Vaxholm
        background-position: -201px -843px;
    }
    &._0381 { // Enköping
        background-position: -201px -913px;
    }
    &._1764 { // Grums
        background-position: -201px -982px;
    }
    &._2403 { // Bjurholm
        background-position: -201px -1052px;
    }
    &._2404 { // Vindeln
        background-position: -201px -1122px;
    }
    &._1961 { // Hallstahammar
        background-position: -201px -1192px;
    }
    &._1490 { // Borås
        background-position: -201px -1262px;
    }
    &._1446 { // Karlsborg
        background-position: -201px -1332px;
    }
    &._1402 { // Partille
        background-position: -201px -1406px;
    }
    &._1488 { // Trollhättan
        background-position: -201px -1480px;
    }
    &._1863 { // Hällefors
        background-position: -201px -1552px;
    }
    &._0580 { // Linköping
        background-position: -201px -1621px;
    }  
    
    // COL 6
    &._2084 { // Avesta
        background-position: -251px -8px;
    }
    &._2082 { // Säter
        background-position: -251px -77px;
    }
    &._2181 { // Sandviken
        background-position: -251px -144px;
    }
    &._2303 { // Ragunda
        background-position: -251px -215px;
    }
    &._0684 { // Sävsjö
        background-position: -251px -283px;
    }
    &._0881 { // Nybro
        background-position: -251px -354px;
    }
    &._0765 { // Älmhult
        background-position: -251px -493px;
    }
    &._2560 { // Älvsbyn
        background-position: -251px -560px;
    }
    &._1267 { // Höör
        background-position: -251px -563px;
    }
    &._1264 { // Skurup
        background-position: -251px -631px;
    }
    &._0127 { // Botkyrka
        background-position: -251px -699px;
    }
    &._0191 { // Sigtuna
        background-position: -251px -773px;
    }
    &._0120 { // Värmdö
        background-position: -251px -843px;
    }
    &._0331 { // Heby
        background-position: -251px -913px;
    }
    &._1783 { // Hagfors
        background-position: -251px -982px;
    }
    &._2425 { // Dorotea
        background-position: -251px -1052px;
    }
    &._2460 { // Vännäs
        background-position: -251px -1122px;
    }
    &._1960 { // Kungsör
        background-position: -251px -1192px;
    }
    &._1438 { // Dals-Ed
        background-position: -251px -1262px;
    }
    &._1482 { // Kungälv
        background-position: -251px -1332px;
    }
    &._1495 { // Skara
        background-position: -251px -1406px;
    }
    &._1473 { // Töreboda
        background-position: -251px -1480px;
    }
    &._1883 { // Karlskoga
        background-position: -251px -1552px;
    }
    &._0586 { // Mjölby
        background-position: -251px -1621px;
    }  

    // COL 7
    &._2081 { // Borlänge
        background-position: -301px -8px;
    }
    &._2021 { // Vansbro
        background-position: -301px -77px;
    }
    &._2182 { // Söderhamn
        background-position: -301px -144px;
    }
    &._2313 { // Strömsund
        background-position: -301px -215px;
    }
    &._0687 { // Tranås
        background-position: -301px -283px;
    }
    &._0882 { // Oskarshamn
        background-position: -301px -354px;
    }
    &._2506 { // Arjeplog
        background-position: -301px -493px;
    }
    &._2513 { // Överkalix
        background-position: -301px -495px;
    }
    &._1276 { // Klippan
        background-position: -301px -563px;
    }
    &._1230 { // Staffanstorp
        background-position: -301px -631px;
    }
    &._0162 { // Danderyd
        background-position: -301px -699px;
    }
    &._0163 { // Sollentuna
        background-position: -301px -773px;
    }
    &._0117 { // Österåker
        background-position: -301px -843px;
    }
    &._0305 { // Håbo
        background-position: -301px -913px;
    }
    &._1761 { // Hammarö
        background-position: -301px -982px;
    }
    &._2481 { // Lycksele
        background-position: -301px -1052px;
    }
    &._2463 { // Åsele
        background-position: -301px -1122px;
    }
    &._1983 { // Köping
        background-position: -301px -1192px;
    }
    &._1445 { // Essunga
        background-position: -301px -1262px;
    }
    &._1441 { // Lerum
        background-position: -301px -1332px;
    }
    &._1496 { // Skövde
        background-position: -301px -1406px;
    }
    &._1485 { // Uddevalla
        background-position: -301px -1480px;
    }
    &._1881 { // Kumla
        background-position: -301px -1552px;
    }
    &._0583 { // Motala
        background-position: -301px -1621px;
    }  

    // COL 8
    &._2080 { // Falun
        background-position: -351px -8px;
    }
    &._2039 { // Älvdalen
        background-position: -351px -77px;
    }
    &._1382 { // Falkenberg
        background-position: -351px -144px;
    }
    &._2321 { // Åre
        background-position: -351px -215px;
    }
    &._0665 { // Vaggeryd
        background-position: -351px -283px;
    }
    &._0834 { // Torsås
        background-position: -351px -354px;
    }
    &._2505 { // Arvidsjaur
        background-position: -351px -493px;
    }
    &._2518 { // Övertorneå
        background-position: -351px -495px;
    }
    &._1290 { // Kristianstad
        background-position: -351px -563px;
    }
    &._1214 { // Svalöv
        background-position: -351px -631px;
    }
    &._0125 { // Ekerö
        background-position: -351px -699px;
    }
    &._0184 { // Solna
        background-position: -351px -773px;
    }
    &._0484 { // Eskilstuna
        background-position: -351px -843px;
    }
    &._0330 { // Knivsta
        background-position: -351px -913px;
    }
    &._1780 { // Karlstad
        background-position: -351px -982px;
    }
    &._2418 { // Malå
        background-position: -351px -1052px;
    }
    &._2280 { // Härnösand
        background-position: -351px -1122px;
    }
    &._1962 { // Norberg
        background-position: -351px -1192px;
    }
    &._1499 { // Falköping
        background-position: -351px -1262px;
    }
    &._1494 { // Lidköping
        background-position: -351px -1332px;
    }
    &._1427 { // Sotenäs
        background-position: -351px -1406px;
    }
    &._1491 { // Ulricehamn
        background-position: -351px -1480px;
    }
    &._1860 { // Laxå
        background-position: -351px -1552px;
    }
    &._0581 { // Norrköping
        background-position: -351px -1621px;
    }  

    // COL 9
    &._2026 { // Gagnef
        background-position: -401px -8px;
    }
    &._0980 { // Gotland
        background-position: -401px -77px;
    }
    &._1380 { // Halmstad
        background-position: -401px -144px;
    }
    &._2380 { // Östersund
        background-position: -401px -215px;
    }
    &._0685 { // Vetlanda
        background-position: -401px -283px;
    }
    &._0884 { // Vimmerby
        background-position: -401px -354px;
    }
    &._2582 { // Boden
        background-position: -401px -493px;
    }
    &._1260 { // Bjuv
        background-position: -401px -495px;
    }
    &._1261 { // Kävlinge
        background-position: -401px -563px;
    }
    &._1263 { // Svedala
        background-position: -401px -631px;
    }
    &._0136 { // Haninge
        background-position: -401px -699px;
    }
    &._0180 { // Stockholm
        background-position: -401px -773px;
    }
    &._0482 { // Flen
        background-position: -401px -843px;
    }
    &._0360 { // Tierp
        background-position: -401px -913px;
    }
    &._1715 { // Kil
        background-position: -401px -982px;
    }
    &._2401 { // Nordmaling
        background-position: -401px -1052px;
    }
    &._2282 { // Kramfors
        background-position: -401px -1122px;
    }
    &._1981 { // Sala
        background-position: -401px -1192px;
    }
    &._1439 { // Färgelanda
        background-position: -401px -1262px;
    }
    &._1462 { // Lilla Edet
        background-position: -401px -1332px;
    }
    &._1415 { // Stenungsund
        background-position: -401px -1406px;
    }
    &._1470 { // Vara
        background-position: -401px -1480px;
    }
    &._1814 { // Lekeberg
        background-position: -401px -1552px;
    }
    &._0582 { // Söderköping
        background-position: -401px -1621px;
    }    

    // COL 10
    &._2083 { // Hedemora
        background-position: -451px -8px;
    }
    &._2183 { // Bollnäs
        background-position: -451px -77px;
    }
    &._1315 { // Hylte
        background-position: -451px -144px;
    }
    &._0604 { // Aneby
        background-position: -451px -215px;
    }
    &._0683 { // Värnamo
        background-position: -451px -283px;
    }
    &._0883 { // Västervik
        background-position: -451px -354px;
    }
    &._2523 { // Gällivare
        background-position: -451px -493px;
    }
    &._1272 { // Bromölla
        background-position: -451px -495px;
    }
    &._1282 { // Landskrona
        background-position: -451px -563px;
    }
    &._1270 { // Tomelilla
        background-position: -451px -631px;
    }
    &._0126 { // Huddinge
        background-position: -451px -699px;
    }
    &._0183 { // Sundbyberg
        background-position: -451px -773px;
    }
    &._0461 { // Gnesta
        background-position: -451px -843px;
    }
    &._0380 { // Uppsala
        background-position: -451px -913px;
    }
    &._1781 { // Kristinehamn
        background-position: -451px -982px;
    }
    &._2417 { // Norsjö
        background-position: -451px -1052px;
    }
    &._2283 { // Sollefteå
        background-position: -451px -1122px;
    }
    &._1904 { // Skinnskatteberg
        background-position: -451px -1192px;
    }
    &._1444 { // Grästorp
        background-position: -451px -1262px;
    }
    &._1484 { // Lysekil
        background-position: -451px -1332px;
    }
    &._1486 { // Strömstad
        background-position: -451px -1406px;
    }
    &._1442 { // Vårgårda
        background-position: -451px -1480px;
    }
    &._1885 { // Lindesberg
        background-position: -451px -1552px;
    }
    &._0584 { // Vadstena
        background-position: -451px -1621px;
    }    


    // COL 11
    &._2029 { // Leksand
        background-position: -505px -8px;
    }
    &._2180 { // Gävle
        background-position: -505px -77px;
    }
    &._1384 { // Kungsbacka
        background-position: -505px -144px;
    }
    &._0686 { // Eksjö
        background-position: -505px -215px;
    }
    &._0885 { // Borgholm
        background-position: -505px -283px;
    }
    &._0764 { // Alvesta
        background-position: -505px -354px;
    }
    &._2583 { // Haparanda
        background-position: -505px -426px;
    }
    &._1231 { // Burlöv
        background-position: -505px -495px;
    }
    &._1262 { // Lomma
        background-position: -505px -563px;
    }
    &._1287 { // Trelleborg
        background-position: -505px -631px;
    }
    &._0123 { // Järfälla
        background-position: -505px -699px;
    }
    &._0181 { // Södertälje
        background-position: -505px -773px;
    }
    &._0483 { // Katrineholm
        background-position: -505px -843px;
    }
    &._0319 { // Älvkarleby
        background-position: -505px -913px;
    }
    &._1762 { // Munkfors
        background-position: -505px -982px;
    }
    &._2409 { // Robertsfors
        background-position: -505px -1052px;
    }
    &._2281 { // Sundsvall
        background-position: -505px -1122px;
    }
    &._1907 { // Surahammar
        background-position: -505px -1192px;
    }
    &._1447 { // Gullspång
        background-position: -505px -1262px;
    }
    &._1493 { // Mariestad
        background-position: -505px -1332px;
    }
    &._1465 { // Svenljunga
        background-position: -505px -1406px;
    }
    &._1487 { // Vänersborg
        background-position: -505px -1480px;
    }
    &._1864 { // Ljusnarsberg
        background-position: -505px -1552px;
    }
    &._0563 { // Valdemarsvik
        background-position: -505px -1621px;
    }


    // COL 12
    &._2085 { // Ludvika
        background-position: -554px -8px;
    }
    &._2104 { // Hofors
        background-position: -554px -77px;
    }
    &._1381 { // Laholm
        background-position: -554px -144px;
    }
    &._0662 { // Gislaved
        background-position: -554px -215px;
    }
    &._0862 { // Emmaboda
        background-position: -554px -283px;
    }
    &._0761 { // Lessebo
        background-position: -554px -354px;
    }
    &._2510 { // Jokkmokk
        background-position: -554px -426px;
    }
    &._1278 { // Båstad
        background-position: -554px -495px;
    }
    &._1281 { // Lund
        background-position: -554px -563px;
    }
    &._1233 { // Vellinge
        background-position: -554px -631px;
    }
    &._0186 { // Lidingö
        background-position: -554px -699px;
    }
    &._0138 { // Tyresö
        background-position: -554px -773px;
    }
    &._0480 { // Nyköping
        background-position: -554px -843px;
    }
    &._0382 { // Östhammar
        background-position: -554px -913px;
    }
    &._1760 { // Storfors
        background-position: -554px -982px;
    }
    &._2482 { // Skellefteå
        background-position: -554px -1052px;
    }
    &._2262 { // Timrå
        background-position: -554px -1122px;
    }
    &._1980 { // Västerås
        background-position: -554px -1192px;
    }
    &._1480 { // Göteborg
        background-position: -554px -1262px;
    }
    &._1463 { // Mark
        background-position: -554px -1332px;
    }
    &._1435 { // Tanum
        background-position: -554px -1406px;
    }
    &._1492 { // Åmål
        background-position: -554px -1480px;
    }
    &._1884 { // Nora
        background-position: -554px -1552px;
    }
    &._0512 { // Ydre
        background-position: -554px -1621px;
    }


    
    
    

    

}