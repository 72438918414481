.loading-overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: white;
    z-index: 10000;
    opacity: .7;
    touch-action: none;
    align-items: center;
    justify-content: center;

    .pulsating-logo {
        transform: scale(.4);
        margin-top: 0;
    }

        .loading-text {
            font-family: Merriweather;
            font-weight: bold;
            font-size: 18px;
        }


        &>div {
            color: black;
            
        }
    }

    .loading-component {
        display: flex; 
        justify-content: center; 
        padding: 20px 0;
        flex-direction: column;
    
        .intermediate-loads {
            display: flex;
            position: relative;
            width: 320px;
            margin-top: 0;
            align-items: center;
            height: 60px;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
    
            .pulsating-logo {
                transform: scale(0.2);
                position: absolute;
                left: -16px;
                top: -70px;
            }
            .loading-text {
                margin-top: 0;
                margin-left: 40px;
                font-size: 18px;
                text-align: center;
                color: #999;
    
            }
        }
    }    