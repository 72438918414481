
.profile-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d7d7d7;

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}