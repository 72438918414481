html {
    overscroll-behavior-y: none;
}

body {
    background-color: #fbfbfb;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
  --toolbar-height: 60px;
  --sidebar-width: 240px;
  --topbar-height: 64px;
  --map-console-height: 150px;

  --primary-color: #5e7093;
  --primary-color-darker: #475570;
  --secondary-color: #e5922c;
  --hyperlink-blue: #123b91;

  --good-bearing: #19ce19;
  --regular-bearing: #19ce19;
  --bad-bearing: #f8ba0b;
  --no-bearing: #ff2222;

  overflow: hidden;

  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hyperlink {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}


@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400&display=swap');

/*@font-face {
    font-family: 'BigShouldersDisplay';
    src: local('BigShouldersDisplay'), url(./resources/fonts/BigShouldersDisplay-Regular.ttf) format('truetype');
  }
  */
