.info-modal {

    &.sidebar-open {
        margin-left: var(--sidebar-width); 
    }

    .MuiPaper-root {
        width: 400px;
        max-width: 80%;
    }

    .header {
        font-size: 16px;
        font-weight: bold;
    }

    &.dev-mode-info-modal {
        p {
            margin: 0;
            font-size: 12px;
        }

        .timestamp {
            display: block;
            font-size: 10px;
            margin-top: 5px;
        }
    }
}