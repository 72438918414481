.user-view { 
    flex-grow: 1;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background-color: #fbfbfb;
    

    /* background-color: #bd5e5e;
    z-index: 2000;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute; */

    .single-user-container {
        max-width: 700px;
        padding: 40px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 10px;
        height: 100%;

        
 

    }

    .drawer-report-view-bg-plate {
        top: 0;
    }

    .loading-user {
        display: inline-flex;
        margin-left: 15%;
    margin-top: 5%;
        flex-direction: column;
        align-items: center;

        .pulsating-logo {
            transform: scale(.4);
            margin-top: 0;
        }
    
        .loading-text {
            font-family: Merriweather;
            font-weight: bold;
            font-size: 18px;
        }
    }
}

.user-profile-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .user-view {
        .loading-user {
            padding-top: 100px;
            width: 100%;
            margin-left: 0;
        }
    }

    .content-root {            
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            svg {
                font-size: 40px;
            }
            
        }
    }
}


@media screen and (min-width: 600px) {
    .user-profile-modal {
        .content-root {    
            width: 580px;
            height: calc(100vh - 20px);
            max-height: 750px;
            border-radius: 5px;
            box-shadow: 5px 5px 15px -2px rgb(0 0 0 / 30%);
        } 
        
        &.sidebar-open {
            margin-left: var(--sidebar-width); 
        }
    }  
}
