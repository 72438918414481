
    .filter-dialog {
        display: flex;
        justify-content: center;
        align-items: center;

        .content-root {
            
            width: 100%;
            height: 100%;
            /* width: 400px;
            height: calc(100vh - 2*var(--topbar-height));
            border-radius: 5px;
            box-shadow: 5px 5px 15px -2px rgb(0 0 0 / 30%); */

            .filter-header {
                width: 100%;
                height: 64px;
                background-color: var(--primary-color);
                color: white;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 20px;

                .filter-header-btns {
                    display: flex;
                    margin-left: auto;
                    .header-btn {
                        cursor: pointer;
                        font-size: 10px;
                        border: 1px solid rgba(255, 255, 255, 0.338);
                        border-radius: 5px;
                        padding: 5px;
                        margin-left: auto;
                        margin-right: 10px;
                        text-transform: uppercase;

                        &.confirm {
                            color: #5d7093;
                            font-weight: 800;
                            background-color: #fbfbfb;
                        }
                    }
                }

                

                .close-btn {
                    margin-right: 12px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .header-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 10px;
                font-size: 14px;
                font-weight: bold;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                padding: 5px;
                color: #5b5b5b;
                text-transform: uppercase;

                .show-button {
                    
                }
                .clear-button {
                    margin-right: 10px;
                    background-color: white;
                    color: #5d906a;
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                span {
                    font-weight: bold;
                }
            }

            .activity-item-2 {
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 90%;
                height: 60px;
                border-radius: 30px;
                position: relative;
                border: 1px solid #bbb;
                margin-top: 5px;
                margin-bottom: 5px;
                padding: 5px;

                &.unselected {
                    opacity: .35;
                }
                &.selected {
                    background-color: white;
                }

                /* &.SKATING .svg-activity-icon { circle {fill: #c34659;} }
                &.HIKING .svg-activity-icon { circle {fill: #d08a04;} }
                &.FISHING .svg-activity-icon { circle {fill: #e47486;} }
                &.SNOWMOBILE .svg-activity-icon { circle {fill: #2196f3;} }
                &.ICE_REPORT .svg-activity-icon { circle {fill: #999;} }
                &.ICESAILING .svg-activity-icon { circle {fill: #8a4ca7;} }
                &.ICE_BATH .svg-activity-icon { circle {fill: #ea66b5;} }
                &.SKIING .svg-activity-icon { circle {fill: #6052fc;} }
                &.SUP .svg-activity-icon { circle {fill: #3dbe55;} }
                &.KAYAK .svg-activity-icon { circle { fill: #0c9024;} } */

                svg {
                    width: 50px;

                    
                }

                .activity-label {
                    margin-left: 20px;
                    font-size: 20px;
                }

                .icon {
                    margin-right: 10px;
                    border-radius: 8px;
                    width: 15px;
                    height: 15px;
                    border: 1px solid #999;
                    
                    position: absolute;
                    right: 0;

                    &.SKATING {
                        background-color: #333;
                    }
                    &.HIKING {
                        background-color: red;
                    }
                    &.SNOWMOBILE {
                        background-color: #55ff55;
                    }
                    &.FISHING {
                        background-color: #72b8f6;
                    }
                    &.ICE_REPORT {
                        background-color: yellow;
                    }
                    &.ICESAILING {
                        background-color: white;
                    }
                    &.ICE_BATH {
                        background-color: #ea66b5;
                    }
                    &.SKIING {
                        background-color: pink;
                    }
                    &.SUP {
                        background-color: rgb(122, 40, 53);
                    }
                    &.KAYAK {
                        background-color: rgb(8, 125, 37);
                    }
                    
                }
            }

            .activity-item {
                display: flex;
                align-items: center;
                width: 200px;
                position: relative;
                .icon {
                    margin-right: 10px;
                    border-radius: 8px;
                    width: 15px;
                    height: 15px;
                    border: 1px solid #999;
                    
                    position: absolute;
                    right: 0;

                    &.SKATING {
                        background-color: #333;
                    }
                    &.HIKING {
                        background-color: red;
                    }
                    &.SNOWMOBILE {
                        background-color: #55ff55;
                    }
                    &.FISHING {
                        background-color: #72b8f6;
                    }
                    &.ICE_REPORT {
                        background-color: yellow;
                    }
                    &.ICESAILING {
                        background-color: white;
                    }
                    &.ICE_BATH {
                        background-color: #ea66b5;
                    }
                    &.SKIING {
                        background-color: pink;
                    }
                    &.SUP {
                        background-color: rgb(122, 40, 53);
                    }
                    &.KAYAK {
                        background-color: rgb(8, 125, 37);
                    }
                    
                }
            }

            .filter-body {
                background-color: #e6e6e6;
                height: calc(100% - 64px);

                .content {
                    background-color: #fbfbfb;
                    padding: 20px;
                    padding-left: 10px;
                    padding-top: 0;
                    overflow-x: hidden;
                    overflow-y: auto;
                    height: 100%;
                    h3 {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    .cb-list {
                        display: flex;
                        flex-direction: column;
                    }

                    .period-pick {
                        display: flex;
                        align-items: center;
                        position: relative;

                        .MuiTextField-root {
                            width: 150px;
                        }

                        svg {
                            position: absolute;
                            right: 5px;
                        }


                        
                    }

                    .period-text {
                        display: flex;
                        flex-direction: column;
                        .period-text-top {

                        }

                        .middle {
                            flex-grow: 1;
                            flex-grow: 1;
                            text-align: center;
                            margin-left: 10px;
                            margin-right: 10px;
                            display: flex;
                            justify-content: space-around;
                            color: #999;

                            .line {
                                flex-grow: 1;
                                display: flex;
                                flex-direction: column;
                                margin-left: -5px;
                                margin-right: -5px;
                                div {
                                    flex-grow: 1;
                                }

                                .hline {
                                    height: 2px;
                                    background-color: #999;
                                    flex-grow: 0;
                                }
                                

                            }
                        }
                        

                        .period-text-bot {
                            margin-top: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;

                            .clickable-date {
                                margin-left: 5px;
                                margin-right: 5px;
    
                                .day-label {
                                    color: #137841;
                                    font-weight: 500;
                                    border: 1px solid;
                                    padding: 10px;
                                    border-radius: 6px;
                                    width: 80px;
                                    display: flex;
                                    justify-content: center;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }                    
                    
                    .button-row {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 40px;

                        .filter-cancel-button {
                            color: #5d906a;
                            border: 1px solid #5d906a;
                        }

                        .filter-confirm-button {
                            margin-right: 10px;
                            background-color: #5d906a;

                            &.Mui-disabled {
                                color: white;
                                opacity: .5;
                            }
                        }
                    }
                }
            }

            
        }

        .subtitle {
            font-size: 13px;
            color: #888;
        }
    }


@media screen and (min-width: 600px) {
    .filter-dialog {
        .content-root {    
            width: 400px;
            height: calc(100vh - 2*var(--topbar-height));
            border-radius: 5px;
            box-shadow: 5px 5px 15px -2px rgb(0 0 0 / 30%);
        }    
    }


    
}