.onboarding-root {
    .first-time-screen {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        h1 {
            margin-top: 40px;
            font-weight: 400;
            font-size: 20px;
        }

        .login-text {
            margin-top: 30px;
        }

        .cookie-text {
            font-size: 12px;
            margin-top: 30px;
            color: #666;
        }

        .img-text-btn {
            display: flex;
            align-items: center;
            
            .btn-img {
                margin-left: auto;
                margin-right: auto;
                height: 20px;
                width: 20px;
                background-size: contain;
                background-repeat: no-repeat;

                &.en {
                    background-image: url(../resources/united-kingdom-flag-round-icon-64.png);
                }
                &.sv {
                    background-image: url(../resources/sweden-flag-round-icon-64.png);
                }
            }
            span {
                margin-left: 7px;
            }

        }  
    }    
}
