.pulsating-logo {
    display: flex;
    margin-top: 50px;
    svg {
        width: 100px;
        height: 100px;
    }
    .anim {
        animation: pulse-logo 1.0s ease-in-out infinite;
        position: relative;
        left: 50px;
    }
    .static {
        position: relative;
        left: -50px;
    }
}

@keyframes pulse-logo {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(2);
	}
}