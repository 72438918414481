
.medal-icon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 7px;

    &.level-1 { //white
        #band-bg {
            fill: #dddddd;
        }
        #band-fg {
            fill: #ffffff;
        }
    }
    &.level-2 { //red
        #band-bg {
            fill: #a90a0a;
        }
        #band-fg {
            fill: #fe8f8f;
        }
        #medal-plate-2 {
            fill: #fe8f8f;
        }
    }
}