.county-plate {
    background-image: url(../resources/counties.jpg);
    background-size: 300px 243px;
    background-repeat: no-repeat;
    background-position: -149px -180px; // missing image poster

    
    &._00 { // I havet
        background-position: -149px -180px;
    }
    &._01 { // Stockholm
        background-position: -49px -120px;
    }
    &._03 { // Uppsala
        background-position: -149px -120px;
    }
    &._04 { // Södermanland
        background-position: -99px -120px;
    }
    &._05 { // Östergötland
        background-position: -249px 0px;
    }
    &._06 { // Jönköping
        background-position: -49px -60px;
    }
    &._07 { // Kronoberg
        background-position: -149px -60px;
    }
    &._08 { // Kalmar
        background-position: -99px -60px;
    }
    &._09 { // Gotland
        background-position: -99px 0px;
    }
    &._10 { // Blekinge
        background-position: 1px 0px;
    }
    &._12 { // Skåne
        background-position: 1px -120px;
    }
    &._13 { // Halland
        background-position: -199px 0px;
    }
    &._14 { // Västra Götaland
        background-position: -249px -120px;
    }
    &._17 { // Värmland
        background-position: -199px -120px;
    }
    &._18 { // Örebro
        background-position: -249px -60px;
    }
    &._19 { // Västmanland
        background-position: -99px -180px;
    }
    &._20 { //Dalarna
        background-position: -49px 0px;
    }
    &._21 { // Gävleborg
        background-position: -149px 0px;
    }
    &._22 { // Västernorrland
        background-position: -49px -180px;
    }
    &._23 { // Jämtland
        background-position: 1px -60px;
    }
    &._24 { // Västerbotten
        background-position: 1px -180px;
    }
    &._25 { // Norrbotten
        background-position: -199px -60px;
    }

}