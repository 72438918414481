.drawer-report-view-container {
    .report-slide {
        padding: 10px;
        padding-top: 0;

        .report-action-panel {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 5px;

            .report-action-button {
                background-color: #fbfbfb;
                width: 40px;
                height: 40px;
                margin-right: 10px;
                padding: 0;
                min-width: initial;
                border-radius: 20px;
                padding-bottom: 5px;

                
                svg {
                    font-size: 32px;
                }
            }

            .action-share, .action-like {
                &.selected {
                    background-color: #e5922c0f;
                    border: 2px solid var(--secondary-color);
                }
            }

            .action-like {
                padding-top: 4px;

                &.liked {
                    color: var(--primary-color);
                }
            }
        }

        .report-properties {
            .prop-group {
                margin-bottom: 0;
            }
            .bg-plate {
                padding-bottom: 0;
            }
        }

        .share-panel {
            background-color: #e5922c0f;
            border: 2px solid var(--secondary-color);
            padding-left: 15px;
            border-radius: 4px;
            margin-bottom: 10px;

            .sp-title {
                margin-top: 10px;
                margin-bottom: 5px;
                font-size: 20px;
                font-weight: 500;
            }

            .fb-button-bg {
                display: flex;
                height: 42px;
                width: 113px;
                background-color: #4767ab;
                justify-content: center;
                border-radius: 21px;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.05);
                }

                .fb-text {
                    background-image: url(../resources/facebook.jpg);
                    height: 40px;
                    width: 79px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    border-radius: 20px;

                    
                }
            }

            .sp-link-title {
                text-transform: uppercase;
                margin-top: 10px;
            }

            .sp-link {
                font-size: 12px;
                margin-bottom: 10px;
            }
        }

        .bg-plate {
            background-color: white;
            border: 1px solid #eee;
            padding: 5px;
            border-radius: 4px;
            margin-bottom: 10px;
        }




        .reporter {
            font-size: 12px;
            text-align: end;
        }

        .small-unit {
            font-size: 12px;
            color: #777;
        }

        .thumb-images {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            width: 325px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;

            .thumb-image {
                width: 105px;
                height: 105px;
            }

            img {
                width: 103px;
                height: 103px;
            }
        }

        

        .prop-group {
            margin-bottom: 15px;

            // last element, empty div to keep other elements above carousel
            &.last {
                height: 30px;
            }
            

            .title {
                text-transform: uppercase;
                font-size: 12px;
                color: #444;
                margin-bottom: 2px;
            }
            .value {
                &.latlng {
                    display: flex;
                    align-items: center;
                    font-size: 12px;

                    .center-btn {
                        margin-left: auto;
                        color: var(--hyperlink-blue);

                        button {
                            padding: 0;
                        }
                    }
                }
                &.bearing {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    svg {
                        fill:#f00;
                    }
                }
            }

            .description {
                font-size: 14px;
            }
            .oneTo5 {
                span:last-child {
                    margin-left: 4px;
                    font-size: 14px;
                    color: #555;
                }
            }
        }

        .divider {
            border-bottom: 1px solid #ddd;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        .quick-filter-items {
            h2 {
                text-transform: uppercase;
                font-size: 12px;
                color: #444;
            }

            .items-row {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }

            .bg-plate {
                display: flex;
                min-height: 60px;
                position: relative;
                flex-direction: column;
                align-items: center;
                min-width: 90px;
                max-width: 90px;
                &:hover {
                    cursor: pointer;
                }

                .filter-item {
                    font-size: 14px;
                }

                .county-plate, .municipality-plate {
                    height: 62px;
                    width: 51px;
                }
                .profile-image {
                    width: 61px;
                    height: 61px;
 
                    
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        
                    }
                }
                .text-label {
                    font-size: 14px;
                    text-align: center;
                    line-height: 15px;
                }
            }
        }

        
    }
}

.report-properties {
    display: flex;
    flex-wrap: wrap;

    .bg-plate {
//        padding: 5px 10px;
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 0;
        margin-right: 10px;

        background-color: white;
        border: 1px solid #eee;
   //     padding: 5px;
        border-radius: 4px;
    //    margin-bottom: 10px;
    }

    .prop-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        min-width: 85px;
    }

    .title {
        text-transform: uppercase;
        font-size: 12px;
        color: #444;
        margin-bottom: 2px;
    }

    .value {
        font-size: 20px;
    }

    .circled-num {
        margin-left: auto;
        margin-right: auto;
        background-color: #ccc;
        font-size: 20px;
        border: 1px solid #aaa;
        display: flex;
        border-radius: 50%;
        //padding: 3px;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        color: white;

        &.lev-1, &.lev-2 { background-color: #08a63d; }
        &.lev-3 { background-color: #e5922c; }
        &.lev-4, &.lev-5 { background-color: #f00; }
    }
    .level-text {
        font-size: 12px;
        color: #777;
    }
}

.like-comment-popover {
    background-color: #414141;
    color: #fbfbfb;
    max-width: 240px;
    padding: 10px;
    font-size: 14px;
}