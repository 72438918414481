.utekoll-logo {
    display: flex;
    margin-top: 50px;
    .uk-logo {
        height: 44px;
        width: 44px;
        margin-left: 10px;
        margin-right: 5px;
      } 

      .uk-text {
        height: 44px;
        width: 135px;
      }
}