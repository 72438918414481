@import "./common.scss";

.user-bio {
    font-size: 15px;

    h2 {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 0;
    }

    p {
        margin-top: 0;
        margin-bottom: 15px;
    }

    .bio-text {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .no-bio-text {
        margin-top: 50px;
        font-size: 20px;
        color: #999;
    }

    .name-stats-section {
        display: flex;
        flex-direction: column;

        


        .name-section {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            padding-left: 20px;
            padding-right: 20px;
            svg {
                width: 70px;
                height: 70px;
                fill: #ddd;
                stroke: #810002;
                stroke-width: 0.5px;
            }
            .profile-image {
                width: 80px;
                height: 80px;
                border-radius: 40px;
                border: 1px solid #aaa;
            }
            .name-dispname {
                display: flex;
                flex-direction: column;
                font-size: 22px;
                margin-left: 20px;
                .name {
                    
                }
                .dispname {
                    font-weight: bold;
                    font-size: 20px;
                }
            }
            
        }

        .stats-section {
            margin-top: 10px;
            margin-bottom: 15px;

            &.report-properties {
                .bg-plate {
                    background-color: #fbfbfb;
                    padding-top: 2px;
                    padding-left: 3px;
                    padding-right: 3px;
                    &.pointer-cursor {
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                .circled-num {
                    background-color: #810002;
                    width: 28px;
                    height: 28px;
                }

                .medal-icon {
                    width: 36px;
                    height: 30px;
                    top: -3px;
                }
            }
        }
    }

        .location-item {
            display: flex;
            flex-direction: column;

            .plate-container {
                position: relative;
                height: 30px;
            }

            .county-plate, .municipality-plate {
                position: absolute;
                top: calc(50% - 30px);
                left: calc(50% - 27px);
                width: 53px;
                height: 60px;
                transform-origin: center;
                transform: scale(0.65);
            }
        }
        .text-label  {
            padding-top: 10px;
        }
        

    .no-public-profile {
        font-size: 18px;
        color: #aaa;
        margin-top: 40px;
    }
}


