@import "./common.scss";

.onboarding-root {
    .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10001;
    
        svg {
            width: 40px;
            height: 40px;
        }
    }

    .onboarding-view {
        //width: 100%;
        display: flex;
        flex-direction: column;
        //padding-top: 20px;
        //padding-bottom: 30px;
        padding: 20px;
        align-items: center;

        .iskoll-logo {
            display: flex;
            align-items: center;
            div:first-child {
                margin-left: auto;
                background-image: url(../resources/logos/iskoll-logo-m.png);
                height: 40px;
                width: 41px;
                background-size: contain;
                background-repeat: no-repeat;
            }
            div:last-child {
                margin-right: auto;
                background-image: url(../resources/logos/wide1-02.png);
                height: 40px;
                width: 110px;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        .step-content {
            text-align: left;
            border-left: 1px solid #bdbdbd;

            .step-text {
                margin-bottom: 5px;
            }
            .display-name-input-group {
                display: flex;
                height: 50px;
                margin-top: 20px;
                margin-bottom: 10px;
                
                .MuiOutlinedInput-root {
                    height: 50px;
                }

                button {
                    margin-left: 10px;
                }
            }
            .display-name-input-error-text {
                font-size: 10px;
                color: red;
                font-weight: bold;
            }

            .formik-error {
                position: relative;
                top: -25px;
                font-size: 10px;
                color: red;
                font-weight: bold;
            }
        }

        .callout-text {
            color: #333;
            margin-top: 40px;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
        }

        .MuiStepper-root {
            margin-top: 50px;
        }


        .registration-success {
            padding-top: 20px;
            text-align: center;
            max-width: 400px;

            .to-login-button {
                @include primary-button;
                font-weight: bold;
                width: 100%;
                margin-top: 50px;
            }
            .info-text {
                font-size: 16px;
                margin-top: 10px;
                color: #555;
            }
        }

        .login-error {
            margin-top: 10px;
            margin-bottom: 20px;
            background-color: red;
            padding: 5px;
            color: white;
            font-weight: bold;
        }    

        .registration-error {
            max-width: 400px;
            padding-top: 40px;
            text-align: center;

            .buttons {
                display: flex;
                margin-top: 50px;
                justify-content: center;

                .to-login-button {
                    @include primary-button;
                    font-weight: bold;
                    width: 70%;
                }

                .to-registration-button {
                    margin-left: 10px;
                }
            }
            
            .info-text {
                font-size: 16px;
                margin-top: 40px;
                color: #555;
            }
        }

        .registration-form, .login-form {
            max-width: 400px;

            .utekoll-logo {
                justify-content: center;
            }

            .social-media  {
                display: flex;
                margin-bottom: 20px;
                margin-top: 10px;

                .fb-btn {
                    background-image: url(../resources/facebook.jpg);
                    height: 44px;
                    width: 88px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-left: 5px;
                    &.loading {
                        opacity: .5;
                    }

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.05);
                    }
                }

                .google-btn {
                    display: flex;
                    align-items: center;
                    border: 1px solid #bbb;
                    padding: 0 10px;
                    height: 44px;
                    margin-right: 5px;
                    .g-logo {
                        margin-left: auto;
                        margin-right: auto;
                        background-image: url(../resources/google-g.png);
                        height: 30px;
                        width: 30px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    span {
                        margin-left: 3px;
                        font-weight: 600;
                        color: #555;
                    }

                    &.loading {
                        opacity: .5;
                    }

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.05);
                    }
                }  
            }

            .title-text {
                margin-top: 40px;
                margin-bottom: 25px;
                color: #555;
                text-align: center;
            }

            .stroked-text {
                display: flex;
                border-top: 1px solid #bdbdbd;
                margin-top: 40px;
                
                span {
                    background-color: white;
                    text-transform: uppercase;
                    font-size: 11px;
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: 6px;
                    padding-right: 6px;
                    position: relative;
                    top: -8px;
                }
            }

            .MuiTextField-root {
                margin-bottom: 30px;
            }

        }

        .login-form {
            .social-media {
                justify-content: center;
            }

            .title-text {
               // margin-top: 20px;
            }

            .button-row {
                margin-top: 40px;
                display: flex;

                .progress-button {
                    flex-grow: 1;
                    background-color: #008043;
                }
                .cancel-button {
                    margin-left: 10px;
                }
            }

            .forgot-pw-link {
                text-align: left;
                margin-top: -15px;
                margin-bottom: 10px;
                font-size: 14px;
                
            }
        }
    }
}
