
body {
    overflow: auto;
    height: 101vh;
    
}

.App {
  text-align: center;
}


.root {
    position: relative;
}

.main-section {
    //position: fixed;
    position: initial;
    
    &.open-sidebar {
        left: 0;
        width: 100%;
    }
    &.closed-sidebar {
        left: 0;
        width: 100%;
    }

    &.map {
        position: fixed;
    }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@media screen and (min-width: 600px) {

    .main-section {
    
        &.open-sidebar {
            left: 240px;
            width: calc(100% - 240px);
        }
        &.closed-sidebar {
            left: 0;
            width: 100%;
            //margin-left: -260px;
            margin-left: -240px;

            &.map {
                margin-left: 0;
            }
        }
    }
}
