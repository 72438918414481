.top-bar {
    position: relative;
    height: var(--topbar-height);
    background-color: var(--primary-color);
    font-weight: bold;
    font-size: 14px;
    padding-right: 10px !important;

    &.dev-mode {
        background-color: rgb(153, 41, 41);
    }

    .burger-button {
        width: 40px;
        height: 40px;
    }

    .logo-white {
        background-image: url(../resources/logos/iskoll-wide-white.png);
        height: 44px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .utekolltext-white {
        height: 44px;
        width: 175px;

        svg {
            height: 44px;
        }

        .ute-text {
            fill: white;
        }
        .koll-text {
            fill: white;
        }

      }

      .empty-push-left {
          margin-left: auto; // ensure all elements after this one are right aligned
      }

      .filter-button {
          color: white;
          margin-left: 20px;
          
          &.has-filter {
            background-color: #fab45e;
            color: var(--primary-color);
          }
      }

      .login-link {
          cursor: pointer;
        //margin-left: auto;
    }  

    .logout-link {
        //margin-left: auto;
        cursor: pointer;
        &:hover {
            color: #fab45e;
        }
    }

      .registration-link {
        cursor: pointer;
        margin-left: 10px;
    }
    
    a {
        color: white;
        text-decoration: none;

        &:hover {
            color: #fab45e;
        }
    }

    .quick-filter-label {
        //position: absolute;
        //left: 60px;
        display: flex;
        background-color: #ffffff;
        height: 45px;
        border-radius: 30px;

        .quick-filter-icon {
            .county-plate, .municipality-plate {
                width: 53px;
                height: 60px;
                transform: scale(0.65) translate(7px, -13px);
            }
            .profile-image {
                width: 45px;
                height: 45px;

                img {
                    width: 39px;
                    height: 39px;
                }
            }
        }
        .quick-filter-content {
            display: flex;
            flex-direction: column;
            min-width: 100px; // to ensure title doesnt line break
            max-width: 120px; //to ensure it fits on iphone SE
            

            .title {
                color: #aaa;
                font-size: 9px;
                text-transform: uppercase;
                //margin-left: 20px;
                margin-top: 4px;
            }
            .filter-prop {
                color: #555;
                white-space: no-wrap;
                //margin-left: 20px;
                font-size: 16px;
                font-weight: 400;
            }
            
        }
        .close-btn {
            margin-right: -10px;
        }
    }


}