@import "./common.scss";

.medal-view {
    position: relative;
    background-color: #FBFBFB;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: calc(100% - var(--topbar-height));
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    .help-icon {
        position: absolute;
        top: 30px;
        right: 40px;
        cursor: pointer;
        svg {
            fill: var(--primary-color);
        }
        
    }

    // You might need to adjust this height based on your layout requirements
    .medalist-list {
        overflow-y: auto;
        max-height: calc(100vh - var(--topbar-height) - 70px); // 70pf from tab panel 48px + 20px top margin
        padding-left: 20px;
        padding-right: 20px;

        .medal-header {
            display: flex;
            align-items: baseline;
            margin-top: 20px;

            .header-text {
                margin-left: 10px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
            }

            .header-subtext {
                display: inline;
                margin-left: 10px;
                margin-top: 0;

                /* display: block;
                margin-left: 0;
                margin-top: -2px; */
                
                font-weight: 500;
                text-transform: none;
                color: #666;
                font-size: 13px;
                text-transform: lowercase;
            }

            
        }

        .medalist {
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 15px;
            border: 1px solid #ddd;
            padding: 5px 15px;
            background-color: white;
            border-radius: 12px;
            cursor: pointer;

            .profile-img {
                width: 50px;
                height: 50px;
                color: #d7d7d7;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    transform: scale(1.2);
                }
            }

            .name {
                margin-left: 20px;
                font-size: 16px;
            }

            .num-reports {
                font-size: 18px;
                font-weight: bold;
                margin-left: auto;

                .report-label {
                    font-weight: 500;
                    font-size: 13px;
                    color: #888;
                    text-transform: lowercase;
                }
            }
        }

        .more-reporters {
            margin-left: 10px;
        }
    }
}



@media screen and (min-width: 600px) {
    .medal-view {
        .medalist-list {
            padding-left: 40px;
            padding-right: 40px;

            /* .medal-header {
                .header-subtext {
                    display: inline;
                    margin-left: 10px;
                    margin-top: 0;
                }
            } */
        }
    }
    
}