.suspense {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    right: 0;

    #preLoadIndicator {
        background-color: #fff;

        .header {
            display: flex;
            align-items: center;
            background-color: #5e7093;
            height: 64px;
            filter: blur(4px);

            .bars {
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                margin-right: auto;

                .bar {
                    width: 40px;
                    height: 6px;
                    background-color: white;
                    margin-bottom: 5px;
                }
            }

            .filter {
                font-size: 30px;
                margin-left: auto;
                margin-right: 20px;
                color: white;
            }
        }

        .bottom {
            display: flex;
            width:100%;
            height: calc(100vh - 64px);

            .sidebar {
                display: flex;
                flex-direction: column;
                width:240px;
                min-width: 240px;
                background-color: white;
                height: 100vh;
                margin-top: -64px;
                filter: blur(4px);
                color: #aaa;
                font-family: sans-serif;

                .h {
                    display: flex;
                    align-content: center;
                    flex-wrap: wrap;
                    font-size: 34px;
                    height: 63px;
                    border-bottom: 1px solid #ccc;
                    padding-left: 20px;
                }

                p {
                    padding-left: 40px;
                    font-size: 18px;
                }
            }

            .mainarea {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
                width:100%;
                background-color: #fbfbfb;
                height: calc(100vh - 64px);
                overflow: hidden;

                .boxes {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    top: 0;
                    align-items: center;
                    animation: y 16s ease-in-out infinite;

                    .reportbox {
                        width: 80%;
                        max-width: 500px;
                        height: 150px;
                        background-color: white;
                        margin-bottom: 20px;
                        border-radius: 20px;
                    }
                }
            }

            @keyframes y {
                0% {
                    top: 0px;
                }
                50% {
                    top: -300px;
                }
                100% {
                    top: 0px;
                }
            }
        }
    }


    @media only screen and (max-width: 600px) {
        #preLoadIndicator .bottom .sidebar {
            display: none;
        }
    }

}