.drawer-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 64px;

    .logo {
        background-image: url(../resources/logos/wide1-02.png);
        height: 44px;
        width: 135px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .utekolllogo {
        height: 44px;
        width: 44px;
        margin-left: 10px;
        margin-right: 5px;
      } 

      .utekolltext {
        height: 44px;
        width: 135px;
      }

      .logo-sq {
        background-image: url(../resources/logos/sq-logo-03.png);
        height: 44px;
        width: 44px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 10px;
        margin-top: 11px;
      }  
}

.side-bar {
    height: 100%;
    position: relative;

    .MuiList-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    .MuiListItemIcon-root {
        color: var(--primary-color);
    }

    .MuiButtonBase-root {
        &:hover {
            background-color: #f5f5f5;
        }
    }

    .new-label {
        font-size: 12px;
        background-color: yellow;
        padding: 2px 4px;
        transform: rotate(344deg);
        border: 1px solid #ddd;
        position: relative;
        left: -8px;
    }

    .logo-icon {
        width: 22px;
        height: 22px;
    }

    .medal-icon {
        top: 0;
        height: 24px;

        #band-bg {
            fill: #5d7093;
        }
        #band-fg {
            fill: #abb7ce;
        }
        #medal-bg {
            fill: #5d7093;
        }
        #medal-plate {
            fill: #abb7ce;
        }
        #medal-plate-2 {
            fill: #fff;
        }
    }

    a, .side-menu-item {
        text-decoration: none;
        color: inherit;
        text-transform: uppercase;
    }

    .version {
        cursor: pointer;
        color: #aaa;
        font-size: 14px;
        padding-bottom: 10px;
        margin-top: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }

    }

    .profile-item {
        display: block;
        border: 1px solid #ddd;
        border-radius: 10px;
        margin: 5px;

        .MuiButtonBase-root {
            padding-left: 5px;
        }

        img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: 1px solid #aaa;
        }

        &:hover {
            background-color: #f5f5f5;
        }
        .MuiButtonBase-root {
            &:hover {
                background-color: transparent;
            }
        }
        .name-block {
            margin-left: 5px;

            .profile-name {
                //margin-left: 70px;
                text-transform: initial;
                font-size: 12px;
                position: relative;
                top: -5px;
                color: #555;
            }
        }
    }

    .language-block {
        margin-left: 30px;
        color: #555555;

        div:first-child {
            margin-bottom: 10px;
        }

        &:focus {
            outline: none;
        }

        .lang-row {
            cursor: pointer;
        }

        span {
            font-family: sans-serif;
            text-transform: capitalize;
            font-size: 15px;
            color: #777;
        }

        .flag {
            width: 15px;
            height: 15px;
            margin-right: 7px;
            position: relative;
            top: 2px;
            display: inline-block;
            background-size: cover;
        }
        .flag-uk {
            background-image: url(../resources/united-kingdom-flag-round-icon-64.png);
        }
        .flag-se {
            background-image: url(../resources/sweden-flag-round-icon-64.png);
        }
    }

    

    .selected {
        background-color: #f5f5f5;
    }
}